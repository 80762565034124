
    import Vue from "vue";

    const END_POINT = "/production-schedule";

    const getProductionScheduleById = id => Vue.axios.get(END_POINT + "/" + id);

    const getProductionScheduleByDates = (productIds, start, end) => Vue.axios.get(END_POINT + "/by-dates", {params: {productIds, start, end} });

    const createProductionSchedule = schedule => Vue.axios.post(END_POINT,  schedule );

    const updateProductionSchedule = schedule => Vue.axios.put(END_POINT  + "/" + schedule.id,  schedule );

    const deleteProductionScheduleById = id => Vue.axios.delete(END_POINT + "/" + id);


    export {
      getProductionScheduleById,
      deleteProductionScheduleById,
      getProductionScheduleByDates,
      createProductionSchedule,
      updateProductionSchedule
    };
    